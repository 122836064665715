<template>
	<cms-text :value="`${prefix}.content`" class="subtitle" :props="{variant: 'medium-heading'}"/>
</template>

<script>

export default {
	props: {
		prefix: String,
		value: Object
	}
}
</script>
